import Maska from 'maska'
import { createApp } from 'vue'
import VueScrollTo from 'vue-scrollto'
import VueCookies from 'vue3-cookies'
import VueSmoothScroll from 'vue3-smooth-scroll'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

const app = createApp(App)

app.use(router)
app.use(Maska)
app.use(VueScrollTo, { duration: 1500 })
app.use(VueCookies)
app.use(VueSmoothScroll)

app.mount('#app')
